export const IFRAME_NAME = 'loginIframe';
export const IFRAME_EVENTS = {
  Loaded: `${IFRAME_NAME}.loaded`,
  ViewChanged: `${IFRAME_NAME}.viewChanged`,
  ErrorOcurred: `${IFRAME_NAME}.errorOcurred`,
  Closed: `${IFRAME_NAME}.closed`,
  CloseButtonClicked: `${IFRAME_NAME}.closeButtonClicked`,
  BackButtonClicked: `${IFRAME_NAME}.backButtonClicked`,
  Submitted: `${IFRAME_NAME}.submitted`,
};

export const triggerIframeEvent = (message: any) => {
  try {
    window.parent.postMessage(message, location.origin);
  } catch (e) {
    console.log('Error in postMessage', e);
  }
};
