import React, { useState, useContext, useEffect } from 'react';
import { useI18nContext, Trans } from '@ecg-marktplaats/js-react-i18n';
import { Text, Divider } from '@hz-design-system/web-ui';

import Classes from './LoginComponent.scss';
import {
  Button,
  Card,
  TextField,
  PasswordTextField,
  UtilsBlock,
  ButtonsBlock,
  Link,
  Checkbox,
  TextRegular,
} from '@hz-design-system/web-ui';
import EnvironmentContext from '../../../contexts/EnvironmentContext';
import { ToastContainer } from 'react-toastify';

import { ClientError } from '@ecg-marktplaats/aurora-node-api-client';
import { trackGAEvent } from '../../../utils/gaTracking';
import useToastNotification from '../../../hooks/useToastNotification';
import type { TToastNotificationProps } from '../../../components/Toast/ToastNotification';
import loginSubmit from '../repositories/loginSubmit';
import {
  TWO_FACTOR_AUTH_SETUP_REQUIRED,
  TWO_FACTOR_AUTH_NEEDED,
  FORCE_PASSWORD_RESET,
  CREDENTIALS_MUST_BE_UPDATED,
  VERIFICATION_REQUESTS_BLOCKED,
  VERIFICATION_CODE_BLOCKED,
  GA_EVENTS,
  WRONG_USERNAME_PASSWORD,
  CONFIRMATION_REQUIRED,
} from '../../../../../common/constants';
import LoginGoogleButton from '../../../components/LoginGoogleButton/LoginGoogleButton';
import LoginTabs from '../../../components/LoginTabs/LoginTabs';
import useThreatMetrix from '../../../hooks/useThreatMetrix';
import { IFRAME_EVENTS, triggerIframeEvent } from '../../../utils/loginIframeEvents';

const LoginComponent = () => {
  const { isEmbeddedInMobileApp, threatMetrix, xsrfToken, successUrl = '/' } = useContext(EnvironmentContext);
  const { t, tExists } = useI18nContext();
  const { toast } = useToastNotification<TToastNotificationProps>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const TmxNoScript = useThreatMetrix(threatMetrix);

  const resetPwdUrl = '/identity/v2/reset-password?forcePwdReset=true';

  useEffect(() => {
    trackGAEvent(GA_EVENTS.LoginBegin);
    const onMessage = (evt: MessageEvent) => {
      switch (evt.data?.type) {
        case 'flagAdIframe.backButtonClicked':
          window.location.assign(successUrl);
          break;
      }
    };
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      trackGAEvent(GA_EVENTS.LoginAttempt);
      await loginSubmit({
        email,
        password,
        xsrfToken,
        rememberMe: remember,
        threatMetrix,
      });
      trackGAEvent(GA_EVENTS.LoginSuccess);
      if (isEmbeddedInMobileApp) {
        triggerIframeEvent({ type: IFRAME_EVENTS.Closed });
      }
      window.location.assign(successUrl);
    } catch (error) {
      const errorMessage = (error as ClientError).message;
      const errorCode = (error as ClientError).code;
      const errorDetails = (error as ClientError).details;

      const { verification, verificationSetup, confirmation } =
        (errorDetails as { verification?: any; verificationSetup?: any; confirmation?: any }) ?? {};

      const flow = 'login';

      //TODO: add wrong username/password attempts counter
      if (errorDetails && (errorDetails as { code?: string }).code === WRONG_USERNAME_PASSWORD) {
        trackGAEvent(GA_EVENTS.LoginFailWrongUsernamePassword);
        toast({
          type: 'error',
          description: t('pages.login.validation.wrong_username_password'),
        });
        setIsLoading(false);
        return;
      }
      let errorCheck = errorMessage;
      if (typeof errorCode === 'string') {
        errorCheck = errorCode;
      }
      trackGAEvent(GA_EVENTS.LoginFail, errorCheck);
      switch (errorCheck) {
        case TWO_FACTOR_AUTH_SETUP_REQUIRED:
          window.location.assign(
            `/identity/v2/two-factor-auth-setup?key=${verificationSetup.key}${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&success_url=${successUrl}&flow=${flow}`,
          );
          break;
        case TWO_FACTOR_AUTH_NEEDED:
          window.location.assign(
            `/identity/v2/two-factor-auth-setup/challenge?key=${verification.id}${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&success_url=${successUrl}&flow=${flow}`,
          );
          break;
        case FORCE_PASSWORD_RESET:
        case CREDENTIALS_MUST_BE_UPDATED:
          if (window.top && isEmbeddedInMobileApp) {
            window.top.location.assign(resetPwdUrl);
          } else {
            window.location.assign(resetPwdUrl);
          }
          break;
        case VERIFICATION_REQUESTS_BLOCKED:
        case VERIFICATION_CODE_BLOCKED:
          toast({ type: 'error', description: t('pages.login.validation.verification_requests_blocked') });
          break;
        case CONFIRMATION_REQUIRED:
          const { required, magicToken } = confirmation;
          const twoFaConfirmation = required.find(({ type }: { type: string }) => type.toLowerCase() === 'phone');
          window.location.assign(
            `/identity/v2/two-factor-auth-setup?magic_token=${magicToken}&key=${twoFaConfirmation.key}&type=phone${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&flow=${flow}`,
          );
          break;
        default:
          const errorMsg = errorMessage?.toLowerCase();
          const errorText = tExists(`pages.login.validation.${errorMsg}`);
          if (errorText) {
            toast({ type: 'error', description: <Trans i18nKey={`pages.login.validation.${errorMsg}`} /> });
          } else {
            toast({ type: 'error', description: t('pages.login.validation.unknown_server_error') });
          }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleChange = () => {
    setRemember(!remember);
  };

  const handleSocialLoginClick = () => {
    setIsLoading(true);
  };

  const handleSocialError = () => {
    setIsLoading(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
  };

  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={Classes.root}>
      <TmxNoScript />
      {!isEmbeddedInMobileApp && <h1>{t('pages.login.page_header')}</h1>}
      <UtilsBlock pt={isEmbeddedInMobileApp ? 'Xs' : ''} pb="Xs" className={Classes.socialLogin}>
        <LoginGoogleButton onSuccess={onSuccess} onError={handleSocialError} onClick={handleSocialLoginClick} />
      </UtilsBlock>
      {isEmbeddedInMobileApp && (
        <UtilsBlock>
          <Divider>{t('pages.login.email_registration')}</Divider>
        </UtilsBlock>
      )}
      <Card className={Classes.card} style={{ textAlign: 'left' }}>
        {!isEmbeddedInMobileApp && <LoginTabs isLoginActive={true} />}
        <UtilsBlock pl="l" pr="l" pb="m" pt="l">
          <TextField
            type="email"
            labelText={t('pages.login.email_label')}
            isRequired={true}
            onChange={(e: any) => setEmail(e.target.value)}
            onKeyPress={onEnterKeyPress}
            id="email"
            value={email}
          />
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="s">
          <PasswordTextField
            type="password"
            labelText={t('pages.login.password_label')}
            isRequired={true}
            onChange={(e: any) => setPassword(e.target.value)}
            onKeyPress={onEnterKeyPress}
            showPasswordText={t('pages.new_password.show')}
            hidePasswordText={t('pages.new_password.hide')}
            id="password"
            value={password}
          />
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="m" className={Classes.remember}>
          <div>
            <Checkbox
              id="remember_me"
              value=""
              className={Classes.remember}
              isChecked={remember}
              onChange={handleToggleChange}
              labelText={t('pages.login.stay_loggedin_label')}
            />
          </div>
          <Link target={isEmbeddedInMobileApp ? '_blank' : '_top'} href="/identity/v2/reset-password">
            {t('pages.login.password_recovery_link')}
          </Link>
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="m">
          <TextRegular>{t('pages.login.public_computer')}</TextRegular>
          <TextRegular>{t('pages.login.public_computer_disclaimer')}</TextRegular>
        </UtilsBlock>
        <ButtonsBlock>
          <Button.Primary onClick={handleSubmit} type="button" disabled={isLoading}>
            {t('pages.login.login_email_address_button')}
          </Button.Primary>
        </ButtonsBlock>
      </Card>
      {isEmbeddedInMobileApp && (
        <UtilsBlock pb="s">
          <Text.Large>
            {t('pages.login.no_account')}{' '}
            <Link href={'/identity/v2/create-account'} target="_blank">
              {t('pages.login.register_here')}
            </Link>
          </Text.Large>
        </UtilsBlock>
      )}
      <UtilsBlock pt={isEmbeddedInMobileApp ? '' : 's'} pl="s" pr="s">
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: t('pages.login.terms_and_conditions_disclaimer_links_no_fb') }} />
      </UtilsBlock>
      <ToastContainer />
    </div>
  );
};

export default LoginComponent;
