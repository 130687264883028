import React, { useContext, useRef, useState } from 'react';

import EnvironmentContext from '../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../utils/gaTracking';
import socialLogin from './repositories/socialLogin';
import useGoogleLogin from '../../hooks/useGoogleLogin';

import Classes from './LoginGoogleButton.scss';
import { ClientError } from '@ecg-marktplaats/aurora-node-api-client';
import {
  USER_NOT_FOUND,
  CONFIRMATION_REQUIRED,
  GA_EVENTS,
  TWO_FACTOR_AUTH_SETUP_REQUIRED,
  TWO_FACTOR_AUTH_NEEDED,
  FORCE_PASSWORD_RESET,
  VERIFICATION_REQUESTS_BLOCKED,
  VERIFICATION_CODE_BLOCKED,
  CREDENTIALS_MUST_BE_UPDATED,
} from '../../../../common/constants';
import { DialogModal } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import useToastNotification from '../../hooks/useToastNotification';
import type { TToastNotificationProps } from '../../components/Toast/ToastNotification';
import { IFRAME_EVENTS, triggerIframeEvent } from '../../utils/loginIframeEvents';

type TLoginGoogleButtonProps = {
  onClick?: Function;
  onError?: Function;
  onSuccess?: Function;
};

const LoginGoogleButton = ({ onError, onSuccess }: TLoginGoogleButtonProps) => {
  const { t, tExists } = useI18nContext();
  const { toast } = useToastNotification<TToastNotificationProps>();
  const [isOpen, setIsOpen] = useState(false);
  const [createAccountUrl, setCreateAccountUrl] = useState('');
  const buttonRef = useRef(null);
  const {
    social: { googleSdkUrl, googleClientID },
    threatMetrix,
    xsrfToken,
    successUrl = '/',
    isEmbeddedInMobileApp,
  } = useContext(EnvironmentContext);

  const resetPwdUrl = '/identity/v2/reset-password?forcePwdReset=true';

  const handleSuccess = async (accessToken: string) => {
    try {
      trackGAEvent(GA_EVENTS.SocialLoginBegin, 'Google');
      const res = await socialLogin({
        accessToken,
        socialNetwork: 'Google',
        threatMetrix,
        xsrfToken,
      });
      if (res?.success) {
        trackGAEvent(GA_EVENTS.SocialLoginSuccess, 'Google');
        if (isEmbeddedInMobileApp) {
          triggerIframeEvent({ type: IFRAME_EVENTS.Closed });
        }
        window.location.assign(successUrl);
      }
    } catch (error) {
      const errorMessage = (error as ClientError).message;
      const errorCode = (error as ClientError).code;
      const errorDetails = (error as ClientError).details;

      const { verification, verificationSetup, confirmation } =
        (errorDetails as { verification?: any; verificationSetup?: any; confirmation?: any }) ?? {};

      const flow = 'google_login';

      let errorCheck = errorMessage;
      if (typeof errorCode === 'string') {
        errorCheck = errorCode;
      }
      trackGAEvent(GA_EVENTS.SocialLoginFail, `Google_${errorCheck}`);
      switch (errorCheck) {
        case TWO_FACTOR_AUTH_SETUP_REQUIRED:
          window.location.assign(
            `/identity/v2/two-factor-auth-setup?key=${verificationSetup.key}${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&flow=${flow}`,
          );
          break;
        case TWO_FACTOR_AUTH_NEEDED:
          window.location.assign(
            `/identity/v2/two-factor-auth-setup/challenge?key=${verification.id}${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&flow=${flow}`,
          );
          break;
        case FORCE_PASSWORD_RESET:
        case CREDENTIALS_MUST_BE_UPDATED:
          if (window.top && isEmbeddedInMobileApp) {
            window.top.location.assign(resetPwdUrl);
          } else {
            window.location.assign(resetPwdUrl);
          }
          break;
        case CONFIRMATION_REQUIRED:
          const { required, magicToken } = confirmation;
          const twoFaConfirmation = required.find(({ type }: { type: string }) => type.toLowerCase() === 'phone');
          window.location.assign(
            `/identity/v2/two-factor-auth-setup?magic_token=${magicToken}&key=${twoFaConfirmation.key}&type=phone${isEmbeddedInMobileApp ? '&mpEmbeddedInMobileApp=true' : ''}&flow=${flow}`,
          );
          break;
        case USER_NOT_FOUND:
          setIsOpen(true);
          setCreateAccountUrl(`/identity/v2/create-account?accessToken=${accessToken}&flow=${flow}`);
          break;
        case VERIFICATION_REQUESTS_BLOCKED:
        case VERIFICATION_CODE_BLOCKED:
          toast({ type: 'error', description: t('pages.login.validation.verification_requests_blocked') });
          break;
        default:
          const errorMsg = errorMessage?.toLowerCase();
          const errorText = tExists(`pages.login.validation.${errorMsg}`);
          if (errorText) {
            toast({ type: 'error', description: t(`pages.login.validation.${errorMsg}`) });
          } else {
            toast({ type: 'error', description: t('pages.login.validation.unknown_server_error') });
          }
          if (onSuccess) {
            onSuccess();
          }
      }
    }
  };

  useGoogleLogin({
    googleSdkUrl,
    googleClientID,
    onSuccess: handleSuccess,
    onError: onError,
    buttonRef,
  });

  return (
    <>
      <div className={Classes.socialButton} ref={buttonRef} id="google-signin-button" />
      {isOpen && (
        <DialogModal
          title={t('pages.create_account.confirm_dialog.title')}
          cancel={{
            text: t('pages.create_account.confirm_dialog.cancel_button'),
            onClick: () => setIsOpen(false),
          }}
          confirm={{
            text: t('pages.create_account.confirm_dialog.confirm_button'),
            onClick: () => {
              setIsOpen(false);
              if (window.top && isEmbeddedInMobileApp) {
                window.top.location.assign(createAccountUrl);
              } else {
                window.location.assign(createAccountUrl);
              }
            },
          }}
          onClose={() => setIsOpen(false)}
          hasOverlayLight={true}
        >
          <p>{t('pages.create_account.confirm_dialog.content')}</p>
        </DialogModal>
      )}
    </>
  );
};

export default LoginGoogleButton;
